import React from "react";
import { trackEvent } from "@leafly-com/web-utils";
import Link from "next/link";

import { useStrainPageContext } from "context/StrainPageContext";
import { StrainRelative } from "custom-types/StrainV2";

import StrainLineageNuclei from "./StrainLineageNuclei";

type Props = {
  strain: StrainRelative;
  altText: string;
  parentOrChild: "parent" | "child";
};

const StrainLineageLink = ({
  altText,
  parentOrChild,
  strain,
}: Props): JSX.Element => {
  const { eventPage } = useStrainPageContext();
  const { name, slug, id, symbol, topTerpName } = strain;

  return (
    <Link
      href={`/strains/${slug}`}
      onClick={() => {
        trackEvent(
          "Strain Lineage",
          "Click",
          `${eventPage} lineage section click`,
          {
            "Strain ID": id,
          },
        );
      }}
      className="flex flex-col items-center text-center"
    >
      <StrainLineageNuclei
        altText={altText}
        mainOrRelative={"relative"}
        strainSymbol={symbol}
        topTerpName={topTerpName}
      />
      <div>
        <div>{name}</div>
        <div className="text-green text-xs">{parentOrChild}</div>
      </div>
    </Link>
  );
};

export default StrainLineageLink;
