import React, { Ref } from "react";

import { StrainRelatives } from "custom-types/StrainV2";

import StrainLineageLink from "./StrainLineageLink";

type Props = {
  parent1Ref: Ref<HTMLDivElement>;
  parent2Ref: Ref<HTMLDivElement>;
  parents: StrainRelatives;
};

const StrainLineageTwoParents = ({
  parent1Ref,
  parent2Ref,
  parents,
}: Props): JSX.Element => (
  <React.Fragment>
    <div className="lineage__left-parent" ref={parent1Ref}>
      <StrainLineageLink
        strain={parents[0]}
        altText="First strain parent"
        parentOrChild="parent"
      />
    </div>
    {parents[1] && (
      <div className="lineage__right-parent" ref={parent2Ref}>
        <StrainLineageLink
          strain={parents[1]}
          altText="Second strain parent"
          parentOrChild="parent"
        />
      </div>
    )}
    <style jsx>{`
      .lineage__left-parent {
        grid-column: 1 / 3;
        grid-row: 1 / 3;
        width: 80px;
      }
      .lineage__right-parent {
        grid-column: 5 / 7;
        grid-row: 1 / 3;
        width: 80px;
      }
    `}</style>
  </React.Fragment>
);

export default StrainLineageTwoParents;
